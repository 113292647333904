<template>
  <div v-if="category" :key="category.id">
    <wine-category-banner :category="category"/>
    <wine-list :category="category"/>
    <div class="content" v-for="(content, contentIndex) in category.content" :key="contentIndex + ':' + category.id" :style="{backgroundColor: content.backgroundColour}">
      <div class="grid-x">
        <div v-for="(column, columnIndex) in content.columns" :key="columnIndex + ':' +  contentIndex + ':' + category.id" class="cell" :class="columnClasses(column)">
          <component v-for="(block, blockIndex) in column.content" v-bind:is="'w-c-' + block.acf_fc_layout" v-bind="block" :key="block.acf_fc_layout + ':' +  blockIndex + ':' +  columnIndex + ':' +  contentIndex + ':' + category.id"></component>
        </div>
      </div>
    </div>
    <related-wine-categories :related="category.relatedCategories" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import WineCategoryBanner from '@/components/WineCategoryBanner.vue';
  import VImage from '@/components/Image.vue';
  import WineList from '@/components/WineList.vue';
  import WCVideo from '@/components/wine-category/Video.vue';
  import WCQuote from '@/components/wine-category/Quote.vue';
  import WCImage from '@/components/wine-category/Image.vue';
  import WCCopy from '@/components/wine-category/Copy.vue';
  import WCEmbedVideo from '@/components/wine-category/EmbedVideo.vue';
  import RelatedWineCategories from '@/components/RelatedWineCategories.vue';

  export default {
    metaInfo: {
      title: 'Black Tower'
    },
    props: {
      slug: String
    },
    components: {
      WineCategoryBanner,
      VImage,
      WineList,
      WCVideo,
      WCQuote,
      WCImage,
      WCCopy,
      WCEmbedVideo,
      RelatedWineCategories
    },
    mounted() {
      this.grabCategory();
    },
    destroyed() {
      this.$root.$off('update:scrollTrigger');
    },
    methods: {
      grabCategory() {
        this.fetchWineCategories()
          .then(() => {
            this.$nextTick(() => {
              this.$root.$emit('update:scrollTrigger');
            })
          });
      },
      ...mapActions(['fetchWineCategories']),
      columnClasses(column) {
        let classes = [];
        classes.push('medium-'+column.size);
        classes.push('medium-offset-'+column.offset);
        if (column.gutter == 'right') {
          classes.push('-gutter-right');
        }
        if (column.gutter == 'left') {
          classes.push('-gutter-left');
        }

        return classes;
      }
    },
    computed: {
      category() {
        return this.$store.getters.getWineCategory(this.slug);
      }
    },
    watch: {
      slug() {
        this.grabCategory();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .cell {
    &.-gutter-left {
      @include breakpoint(medium) {
        padding-left: vw-relative(16);
      }
    }
    &.-gutter-right {
      @include breakpoint(medium) {
        padding-right: vw-relative(16);
      }
    }
  }
  .content {
    @include breakpoint(medium) {
      margin-bottom: 12px;
    }
  }
</style>

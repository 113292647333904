<template>
  <div class="embed-container" v-html="code"/>
</template>

<script>
  export default {
    props: {
      code: String
    }
  }
</script>

<style lang="scss" scoped>
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    &::v-deep iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>

<template>
  <video class="video" :src="video.url" controls></video>
</template>

<script>
  export default {
    props: {
      video: Object
    }
  }
</script>

<style lang="scss" scoped>
  .video {
    width: 100%;
  }
</style>

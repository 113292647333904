<template>
  <v-image
    class="image"
    :image="image"
  />
</template>

<script>
  import VImage from '@/components/Image.vue';
  export default {
    components: {
      VImage
    },
    props: {
      image: Object
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .image {
    width: 100%;
    height: auto;
    padding: vw-relative(0 0 30 20, 320);
    @include breakpoint(medium) {
      padding: 0 0 vw-relative(60);
    }
  }
</style>
